.privacyimges {
    margin-top: 3.8rem !important;
    background: linear-gradient(to right, #2bc0e4, #eaecc6);
}

.privacyimges img {
    height: 100%;
    width: 100%;
}

.privacycontent ul div {
    font-size: 30px;
    font-weight: bolder;
    margin-top: 65px;

}

.privacycontent ul li {
    list-style: none;
    border-bottom: 3px solid #043b68;
}

.privacycontent h5 {
    color: #06848d;
    font-weight: bold;
    font-size: 22px;
    margin-top: 30px;
}

.privacycontent p {
    line-height: 30px;
    font-size: 16px;
    font-weight: 400;
    margin-top: 10px;
    text-align: justify;
}

.privacycontent span {
    color: #ff0000;
    font-size: 15px;
    margin-left: -18px;
    margin-right: 5px;
    padding-left: 15px;
}