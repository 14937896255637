@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap");

* {
  margin: 0px;
  padding: 0px;
  font-family: "Poppins", sans-serif;
}

/* .App {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    width: 100%;
    height:40rem;
    max-height: 100%;

} */

.main1 {
  background-color: rgba(235, 233, 233, 0.589);
}

.two {
  padding-top: 3.8rem;
}

.carousel-control-prev:hover {
  box-shadow: inset 60px 0 40px 0px rgba(102, 102, 102, 0.514);
}

.carousel-control-next:hover {
  box-shadow: inset -60px 0px 40px 0px rgba(102, 102, 102, 0.514);
}

@media screen and (max-width: 576px) {
  .carousel-control-next:hover {
    box-shadow: none;
  }
}

@media screen and (max-width: 576px) {
  .carousel-control-prev:hover {
    box-shadow: none;
  }
}

.scrollimg {
  width: 100%;
  height: 32rem;
}

.imageinnertxt1 {
  text-align: start;
  margin-bottom: 7rem;
  color: white;
}

.imageinnertxt1 i {
  color: white;
  margin-right: 10px;
}

.imageinnertxt1 h1 {
  font-size: 4rem;
  font-weight: 900;
  margin-bottom: 1rem;
}

.imageinnertxt1 p {
  font-size: 20px;
  margin-bottom: 2rem;
  font-weight: 900;
}

.imageinnertxt2 {
  text-align: start;
  margin-bottom: 6rem;
  color: white;
}

.imageinnertxt2 h5 {
  font-size: 3.1rem;
  margin-bottom: 2rem;
  font-weight: 900;
}

.imageinnertxt2 p {
  font-size: 18px;
  font-weight: 600;
}

.imageinnertxt3 {
  text-align: start;
  margin-bottom: 10rem;
  color: white;
}

.imageinnertxt3 h1 {
  font-size: 3.5rem;
  font-weight: 900;
}

.imageinnertxt3 p {
  font-size: 20px;
  margin-top: 2rem;
}

.img1btn1 {
  color: white;
  font-weight: 900;
  font-size: 19px;
  background-color: #061080;
  height: 2.9rem;
  width: 10rem;
  border-radius: 20px;
  border: 2px solid rgb(0, 255, 242);
  transition: 0.9s;
}

.img1btn1:hover {
  transition: 0.9s;
  transform: scale(1.1);
}

@media screen and (max-width: 576px) {
  .img1btn2 {
    margin-top: 30px;
  }
}

.three {
  min-height: 33rem;
  align-items: center;
  display: flex;
  justify-content: center;

  /* margin-top: 50px; */
  /* background-color: #061080; */
}

/* .split {
    padding: 1rem;
    grid-row-gap: 25px;
} */

.split1 {
  cursor: pointer;
  min-height: 17rem;
  border-radius: 16px;
  border: 1px solid black;
  background-color: white;
  align-items: center;
  display: flex;
  padding: 0;
  text-align: center;
}

.split1 h2 {
  color: #06848d;
}

.split1 p {
  font-size: 15px;
  padding: 0px 10px;
  font-weight: lighter;
  /* margin-left: 30px; */
  /* margin-top: 20px; */
}

@media screen and (max-width: 576px) {
  .split1 {
    min-height: 13rem;
    margin-top: 10px;
  }

  .split1 img {
    height: 100px;

    /* margin-left: 30px; */
  }

  .split1 h2 {
    font-size: 20px !important;
  }

  .split1 p {
    font-size: 12px;
  }
}

.split1:hover {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border: none;
  transform: scale(1.01);
  transition: 0.5s !important;
}

.five {
  min-height: 25rem;
  background-color: #ffffff;
}

.five span {
  color: red;
}

.five img {
  height: 40px;
  margin-top: -5px;
}

.five p {
  margin-top: 15px;
  text-align: justify;
}

.five h1 {
  font-size: 6rem;
  text-align: center;
  font-style: italic;
  font-weight: 900;
  color: #06848d;
  font-family: "PT Sans" !important;
}

.five h2 {
  text-align: center;
  color: #6306f8;
}

.box {
  cursor: pointer;
  justify-content: center;
  display: flex;
  padding: 80px 0px;
  /* height: 25rem; */
  /* margin-top: 50px; */
  background-color: #f3f3f3;
}

.box div {
  width: 250px;
  height: 280px;
  transition: 1s;
  border-radius: 22px;
  box-shadow: rgba(0, 0, 0, 0.479) 0px 5px 15px;
}

.box div:hover {
  transform: scale(1.05);
  z-index: 2;
  /* box-shadow: 2px 2px 2px #000; */
}

.box img {
  height: 100%;
  width: 100%;
  /* background-color: #06848d; */
}

@media screen and (max-width: 576px) {
  .box div:hover {
    transform: scale(1.05);
    box-shadow: 2px 2px 2px #000;
  }

  .box {
    padding: 40px 0px;
  }
}

@media screen and (max-width: 576px) {
  .box div {
    height: 140px;
  }

  .box2 h5 {
    display: none;
  }
}

.slideimg {
  border-radius: 22px;
}

.box1 {
  margin-right: -2.5rem;
}

.box2 {
  position: relative;
  margin-right: -2.5rem;
  background-image: linear-gradient(
    to top,
    #d5d4d0 0%,
    #d5d4d0 1%,
    #eeeeec 31%,
    #efeeec 75%,
    #e9e9e7 100%
  );
}

.box3 {
  z-index: 1;
}

.box4 {
  margin-left: -2.5rem;
  position: relative;
}

.box5 {
  margin-left: -2.5rem;
  background-image: linear-gradient(to top, #accbee 0%, #e7f0fd 100%);
}

.eight {
  padding: 30px;
}

.partnerbtn {
  width: 17rem;
  height: 3.5rem;
  color: white;
  border: none;
  text-align: center;
  background-color: rgb(15, 132, 199);
  font-size: 22px;
  font-weight: 500;
  border-radius: 30px;
}

.partnerbtn:hover {
  background: linear-gradient(to right, #298eaf 0%, #13c2c2 100%);
}

.nine {
  min-height: 46rem;
  background-color: white;
}

.businesstxt {
  min-height: 4.2rem;
  border-bottom: 1px solid rgb(163, 162, 162);
}

.businesstxt p {
  font-size: 16px;
  font-weight: lighter;
}

.ten {
  min-height: 60rem;
  background-color: rgb(129, 129, 129);
  text-align: center;
  font-size: 45px;
  font-weight: 900;
}

.eleven {
  min-height: 31rem;
  background-color: rgb(255, 255, 255);
}

@media screen and (max-width: 906px) {
  .eleven {
    min-height: 8rem;
  }
}

.eleven img {
  width: 100%;
  margin-top: 10px;
}

.ratings {
  position: relative;
  top: 5rem;
}

.ratings h1 {
  font-size: 2.5rem;
  font-weight: 500;
  color: rgb(255, 255, 255);
}

.ratings h5 {
  font-size: 1.25rem;
  font-weight: 500;
  color: #ffffff;
  line-height: 0.9;
}

.ratings h6 {
  padding-top: 20px;
  font-size: 1.8rem;
  font-weight: 900;
  color: #ffffff;
}

.ratingsbtn {
  height: 2.5rem;
  width: 9.8rem;
  background-color: #16f4fc;
  color: rgb(0, 0, 0);
  border-radius: 30px;
  border: none;
  font-size: 16px;
  font-weight: 500;
}

.twelve {
  background-color: rgb(255, 255, 255);
}
