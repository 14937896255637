 .refundimg img {
     height: 80%;
     width: 100%;
     padding-top: 3.8rem;
 }

 .termscontent ul div {
     font-size: 30px;
     font-weight: bolder;
     margin-top: 64px;
 }



 .termscontent ul li {
     list-style: none;
     border-bottom: 3px solid #043b68;
 }

 .termscontent span {
     color: #ff0000;
     font-size: 15px;
     padding-left: 15px;
     margin-left: -18px;
     margin-right: 5px;
 }

 .termscontent p {
     line-height: 30px;
     font-size: 16px;
     font-weight: 400;
     margin-top: 10px;
     text-align: justify;
 }