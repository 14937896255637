.contactusimg {
    padding-top: 3.8rem;
}

.contactusimg img {
    width: 100%;
}

.customerdetails {
    margin-top: 60px;
    min-height: 80px;
}

.customerdetails h4 {
    color: #06848d;
}

.helpcontent h5 {
    color: #06848d;
    margin-top: 4rem;
}

.helpcontent h6 {
    font-size: 1.25rem;
}

.helpcontent a {
    text-decoration: none;
    font-size: 18px;
}

.helpcontent p {
    font-size: 17px;
    font-weight: lighter;
    margin-top: 25px;
}

.contactaddress {
    padding: 50px 0px;
}

.contactaddress h2 {
    color: #06848d;
}

.contactaddress li {
    font-size: 18px;
    font-weight: lighter;
    line-height: 2.7rem;
    list-style: none;
}

@media screen and (max-width: 576px) {
    .contactaddress {
        padding: 10px;
    }

    .helpcontent a {
        text-decoration: none;
        font-size: 15px !important;
    }
}