/* @media (min-width : 992px) {
    .offcanvas {
        visibility: visible;
        position: relative;
        background: none;
        border: none;
        justify-content: end;
    }
} */

/* OFF CANVA */
@media (max-width : 992px) {
    .offcanvas {
        visibility: visible;
        position: relative;
        background: none;
        border: none;
        justify-content: end;
        width: 250px !important;
    }

    .offcanvas-start-lg {
        top: 0;
        left: 0;
        border-right: 1px solid rgba(0, 0, 0, .2);
        transform: translateX(-100%);
    }

    .navbar-toggler {
        cursor: pointer;

    }

    .navbar-toggler:hover {
        transform: scale(1.1);
        transition: .5s;
    }

    .dropdown li a {
        padding: 5px;
    }

    .dropdown ul {
        min-width: 13.5rem;
    }


}

@media all and (min-width: 992px) {
    .navbar-nav li:hover ul.dropdown-menu {
        display: block;
        transition: .5s;
        opacity: 1;
        visibility: visible;
        top: 100%;
        transform: rotatex(0deg);
    }

    .navbar .nav-item .dropdown-menu {
        display: block;
        opacity: 0;
        visibility: hidden;
        transition: .3s;
        margin-top: 0;
    }

}





/* .navbar .nav-item .dropdown-menu {
    display: block;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
    margin-top: 0;
}

.navbar .nav-item:hover .dropdown-menu {
    transition: .5s;
    opacity: 1;
    visibility: visible;
    top: 100%;
    transform: rotatex(0deg);
} */










.dropdown ul {
    border: 1px solid rgba(8, 8, 8, 0.719);
    padding: 0;
}

.dropdown li a {
    padding-top: 8px;
    font-size: 15px;
    color: black;
    letter-spacing: .5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.24);
}

.dropdown li a:hover {
    color: white;
    background-color: rgba(2, 96, 119, 0.836);
}


/* HEAD LOGO */

.head-logo {
    display: flex;
    align-items: center;
    padding: 5px;
}

.head-logo .logo-circle {
    height: 60px;
}

.head-logo .logo-text {
    height: 22px;
    margin-top: 3px;
}


/* NAV LINKS */

.navbar {
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.425) 0px 2px 5px -1px;
}

.navbar-nav {
    display: flex;
    width: 85%;
    justify-content: space-evenly;
}

.navbar-nav .nav-link {
    padding: 10px;
    min-width: 2rem;
    font-size: 14px;
    letter-spacing: 1px;
    height: 40px;
    color: black;
}


.navbar-nav .nav-link:hover {
    border: none;
    transition-delay: 0.1s;
    color: black !important;
    border-bottom: 2px solid blue !important;
}