input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 99999s;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.signup-body {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-image: url(https://wp.20karma.com/sletne.org/wp-content/uploads/2018/11/cropped-designbig.jpg);
}

.form-body {
    padding: 2.5% 0%;
    margin-top: 3.8rem;
    height: 100%;
    background-color: rgb(255, 255, 255);
    border-radius: 12px;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}


.form-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: aqua; */

}

.form-content h2 {
    letter-spacing: 3px;
    margin-top: 10px;
}



.form-img p, a {
    font-size: 14px;
    letter-spacing: 1px;
}

.form-content .input-div {
    margin-top: 35px;
    padding-bottom: 3px;
    border-bottom: 1px solid rgb(199, 198, 198);

}

.input-field {
    padding: 8px;
    width: 265px;
    border: none;
    background-color: transparent;
    position: absolute;
    outline: none;
    border: none;
}

.input-field:focus {
    outline: none;
    background: transparent;
}

.input-field::placeholder {
    color: rgb(56, 55, 55);
    font-size: 14px;
    padding-left: 10px;
}

.form-content i {
    position: relative;
    font-size: 22px;
    z-index: 1;
    color: rgb(111, 112, 112);
}

.form-content p {
    color: red;
    text-align: center;
}



@media screen and (max-width:576px) {
    .form-img {
        display: none;
    }

    .input-field {
        width: 233px !important;
    }
}

.form-content button {
    padding: 7px 24.5px 7px 24.5px;
    margin-bottom: 10px;
    border: none;
    outline: none;
    font-size: 17px;
    letter-spacing: 1px;
    text-shadow: none;
    font-weight: 600;
    border-radius: 50px;
    background-color: rgb(3, 148, 192);
    transition: .5s ease;
    color: white;
}

.form-content button:hover {
    background-color: rgb(15, 24, 27);
    color: white;
}



.bar {
    display: block;
    position: relative;
    width: 100%;
}

.bar::before, .bar::after {
    content: "";
    border-bottom: 2px solid rgb(4, 176, 255);
    width: 0px;
    margin-top: 2.1px;
    position: absolute;
    transition: all 0.2s ease;
}


.bar::before {
    left: 50%;
}

.bar::after {
    right: 50%;
}

.input-field:focus~.bar::before,
.input-field:focus~.bar::after {
    width: 50%;
}