.fourcardicon {
    font-size: 60px;
    color: #1ec0d6;
    /* background-color: #00a2b8; */
}

@media screen and (max-width:576px) {
    .fourcardmain {
        /* background-color: burlywood !important; */
        text-align: center;
        padding: 0px !important;
    }

    .fourcardicon {
        /* font-size: 40px !important; */
    }

    .fourcardhead {
        margin: 0px !important;
        font-size: 13px !important;
    }

    .fourdcardcontent {
        padding: 2px !important;
    }

    .businessloan-content {
        text-align: center !important;
    }
}

.fourcardhead {
    margin-top: 20px;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: .5px;
}

.fourdcardcontent {
    line-height: 25px;
    font-size: 14px;
    padding: 10px 50px 30px 0px;
}

.businessloan-body {
    height: 100%;
    width: 100%;
    background-color: white;
    margin-top: 2rem;

}


.businessloan-head {
    text-align: center;
    margin-top: 4.1rem;
    letter-spacing: 5px;
    color: rgb(34, 33, 33);
    background-color: rgba(6, 171, 248, 0.493);
}

.businessloan-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 10px !important;
}

.businessloan-content .business-sub-head {
    font-size: 17px;
    letter-spacing: .5px;
    margin-top: 20px;
    font-weight: 600;
    color: #3a3a3a;
}

.businessloan-content p {
    font-size: 15px;
    line-height: 24px;
}