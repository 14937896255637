.mains {
    background-color: rgba(238, 238, 238, 0.507);
}

.abotusheadimg {
    padding-top: 3.8rem;
}

.abotusheadimg img {
    height: 100%;
    width: 100%;
}

.aboutcontent {
    padding-top: 4.5rem;
    justify-content: center !important;
}

.aboutcontent h2 {
    color: #06848d;
}

.aboutcontent div {
    display: flex;
    justify-content: center;
}

.aboutcontent p {
    font-size: 18px;
    line-height: 2.1rem;
    margin-top: 10px;
}


.at {
    min-height: 25rem;
    margin-top: 6rem;
}

.ss {
    min-height: 200px;
    margin-top: 10px;
}

.imgback {
    padding-top: 20px;
    display: flex;
    justify-content: center;
}

.imgback1 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    width: 70px;
    border-radius: 50px;
    background-color: rgb(255, 255, 255);
}

.ss h5 {
    font-size: 21px;
    color: #06848d;
}

.ss p {
    margin-top: 10px;
}

.founder {
    padding: 20px;
    grid-row-gap: 50px !important;
    margin-bottom: 50px;
}

.founder div {
    cursor: pointer;
    transition: 2s;
}

.ourfounders {
    font-size: 37px;
    font-weight: bolder;
    text-align: center;
    margin-bottom: 25px;
    color: #06848d;
}

.pox {
    position: relative;
    height: 400px;
    border-radius: 20px;
    background-color: rgb(0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.pox::after {
    content: "";
    position: absolute;
    width: 500px;
    height: 500px;
    background-image: conic-gradient(transparent, transparent, transparent, #ff0bff);
    animation: animate 4s linear infinite;
    animation-delay: -2s;
}

.pox::before {
    content: "";
    position: absolute;
    width: 500px;
    height: 500px;
    background-image: conic-gradient(transparent, transparent, transparent, #00ccff);
    animation: animate 4s linear infinite;

}

@keyframes animate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.pox span {
    position: absolute;
    inset: 5px;
    background: #0f142c;
    border-radius: 16px;
    z-index: 1;
}

.founderimg {
    position: relative;
    z-index: 1;
    height: 200px;
    width: 200px;
    border-radius: 100px;
    display: flex;
    justify-content: center;

}

.pox:hover {
    transform: scale(1.04);
}

@media screen and (max-width: 576px) {
    .pox:hover {
        transform: scale(1);

    }
}


.founderimg img {
    border-radius: 100px;
    height: 80%;
}

.founderdetails {
    z-index: 1;
    /* height: 100px; */
    text-align: center;
    margin-top: 20px;
}

.founderdetails h5 {
    font-size: 20px;
    font-weight: bolder;
    color: #ffffff;

}

.founderdetails p {
    font-size: 16px;
    color: white;
}