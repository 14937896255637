.apisolutionhead {
    padding-top: 3.8rem;
}

.apisolutionhead p {
    line-height: 30px;
}

.apisolutionhead h2 {
    letter-spacing: 2px;
    font-weight: 600;
    margin-bottom: 10px;
}

.apisolutionhead .sideimg {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    /* height: 350px; */
    width: 500px;
}

@media screen and (max-width: 576px) {
    .top-p-tag div {
        padding: 10px !important;
    }

    .bottom-p-tag {
        margin-top: 20px !important;
    }

    .bottom-p-tag div {
        padding: 10px !important;
    }

    .apisolutionhead .sideimg {
        height: 200px;
        width: 300px;
    }
}