.fourservice img {
  width: 130px;
  /* height: 80px; */
}

.fourservicerow a {
  margin-top: 40px;
  text-decoration: none !important;
}

.fourservice p {
  text-align: center;
  font-size: 1.1rem;
  margin-top: 10px;
  font-weight: 600;
  color: black;
  text-decoration: none !important;
}

.fourservice a div:hover {
  transform: scale(1.1);
  transition: all 1s;
}

.fourservice a div {
  background-color: white;

  /* background: linear-gradient(to right, #74ebd5, #acb6e5); */
  width: 10rem;
  height: 10rem;
  border-radius: 30px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

/* / ---------------------media query----------------------------- / */
@media screen and (max-width: 500px) {
  .fourservice a div {
    width: 8rem;
    height: 8rem;
  }
}

@media screen and (max-width: 500px) {
  .fourservice a p {
    font-size: 1rem;
  }
}

.servicesimghead {
  padding-top: 3.8rem;
}

.Paypeservices {
  min-height: 32rem;
  background-color: white;
  padding: 50px 0px 50px 0px;
}

.servicescontent {
  min-height: 30rem;
  display: flex;
  justify-content: center;
}

.servicescontent h4 {
  color: #06848d;
  margin-top: 10px;
}

.servicescontent p {
  font-size: 16px;
}

.servicescontent div {
  text-align: center;
  min-height: 350px;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  margin: 10px;
}

.servicescontent div:hover {
  transform: scale(1.02);
  transition: 1s;
  background-color: #e4e4e4;
  background-image: radial-gradient(
      at 50% 100%,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.25) 0%,
      rgba(0, 0, 0, 0.25) 100%
    );
  background-blend-mode: screen, overlay;
  /* background: linear-gradient(to right, #74ebd5, #acb6e5); */
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  box-shadow: 2px 2px 2px #000;
  cursor: pointer;
}

@media screen and (max-width: 576px) {
  .servicescontent div:hover {
    transform: none;
  }
}

.servicescontent img {
  margin-top: 30px;
  height: 100px;
}

.servicescontent1 p {
  font-size: 18px;
  line-height: 2.1rem;
}
