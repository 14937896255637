/* FOTTER ICON */

.footer-icon {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.footer-icon li {
  position: relative;
  list-style: none;
  margin: 10px 15px 0px 15px;
  cursor: pointer;
}

.footer-icon li a {
  text-decoration: none;
  color: inherit !important;
}

.footer-icon li a .fa {
  font-size: 2.4em;
  color: rgb(83, 83, 83);
}

.footer-icon li a::before {
  font-family: "FontAwesome";
  position: absolute;
  top: -10px;
  left: 0px;
  font-size: 2.4em;
  height: 0;
  overflow: hidden;
  transition: 0.5s ease-in-out;
}

.footer-icon .linkedin a::before {
  content: "\f08c";
  /* background-image: linear-gradient(45deg,
            #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%,
            #bc1888 100%); */
  color: #0077b5;
  /* -webkit-background-clip: text; */
  /* -webkit-text-fill-color: transparent; */
  border-bottom: 2px solid #0077b5;
}

.footer-icon .twitter a::before {
  content: "\f099";
  color: #1da1f2;
  border-bottom: 2px solid #1da1f2;
}

.footer-icon .facebook a::before {
  content: "\f082";
  color: #4267b2;
  border-bottom: 2px solid #4267b2;
}

.footer-icon .playstore a i:hover {
  color: #e70808;
  transform: scale(1.1);
  transition: 0.3s ease;
}

.footer-icon li:hover a::before {
  height: 50px;
}

/* BOTTOM LINES */

.footimg img {
  height: 100px;
}

@media screen and (max-width: 992px) {
  /* .footimg {
        height: 65px !important;
    } */

  .foot a {
    font-size: 0.83rem !important;
  }

  .foot1 {
    text-align: center !important;
  }
}

.foot1 {
  /* margin-top: 50px !important; */
}

.foot1 .foothead {
  margin: 1.5rem 0rem;
  font-size: 15.5px;
  color: #089ba5;
  font-weight: 600;
  letter-spacing: 1px;
}

.foot a {
  text-decoration: none;
  color: rgb(0, 0, 0);
}

.foot a p span {
  color: #000000;
  position: relative;
  padding-bottom: 5px;
}

.foot a p span::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  border-radius: 4px;
  background: linear-gradient(to left, #1e7db2, #089ba5);
  bottom: 0;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.foot a p span:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}

.footerdetails p {
  font-size: 13px;
  color: #444444;
  letter-spacing: 0.5px;
  line-height: 1.5rem;
}

.footerdetails h6 {
  font-size: 14px;
  color: #3f3e3e;
  letter-spacing: 0.5px;
}

/* charge-footer */
.table th,
.table td {
  border: 2px solid #a7a7a7;
  padding: 8px;
}
.table {
  overflow: hidden;
  overflow-x: visible;
}
@media screen and (max-width: 576px) {
  .table th,
  .table td {
    border: 2px solid #a7a7a7;
    padding: 3px 0px 3px 3px !important;
    font-size: 11.5px !important;
  }
}
